<template>
  <router-view :company="company" />
</template>

<script>
/**
 * BaseCompany
 *
 * Exposes company object as prop to all child components.
 */
export default {
  name: 'BaseCompany',
  beforeRouteLeave (to, from, next) {
    this.$store.commit('clear', 'selectedCompany')
    next()
  },
  computed: {
    company () {
      return this.$store.getters['rooof/getCompanyById'](this.$route.params.cid)
    }
  },
  watch: {
    company: {
      immediate: true,
      handler () {
        this.setSelectedCompany()
      }
    }
  },
  methods: {
    /**
     * Sets the selected company in the store.
     */
    setSelectedCompany () {
      const selectedCompany = {
        name: this.company.name,
        human_name: this.company.human_name,
        id: this.company.id
      }
      this.$store.commit('set', ['selectedCompany', selectedCompany])
    }
  }
}
</script>
